import React, { Component } from "react";
import Badge from "react-bootstrap/Badge";
import "./StatsCard.css";

export default class StatsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      icon: props.icon,
      title: props.title,
      text: props.text
    };
  }

  render() {
    return (
      <div>
        <div className="card border-secondary flex-row mb-1">
          <div className="row g-0">
            <div className="col-md-3 container_stats">
              <div className="center_stats">
                <Badge pill bg="info">
                  <i className={this.props.icon}></i>
                </Badge>
              </div>
            </div>
            <div className="col-md-9">
              <div className="card-body">
                <h6 className="card-title">{this.props.title}</h6>
                <p className="card-text text-muted">{this.props.text}</p>
                <p className="card-text text-muted">
                  <small>Último mês</small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
