import React from "react";
/*
 * import Container from "react-bootstrap/Container";
 * import Form from "react-bootstrap/Form"; */
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
/* import Badge from "react-bootstrap/Badge";
 * import Table from "react-bootstrap/Table";
 * import Spinner from "react-bootstrap/Spinner";
 * import Alert from "react-bootstrap/Alert"; */
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";
import StatsCard from "../StatsCard";
import "../StatsCard.css";

const rows = [
  {
    code: 1,
    total_suppliers: 3,
    viewed: 2,
    answered: 1,
    sent_at: "13/08/2021",
    finish_at: "20/08/2021",
    status: "Andamento",
    action: (
      <Button variant="primary">
        <i className="bi bi-eye-fill"></i>
      </Button>
    )
  },
  {
    code: 2,
    total_suppliers: 4,
    viewed: 3,
    answered: 2,
    sent_at: "21/09/2021",
    finish_at: "28/09/2021",
    status: "Pendente",
    action: (
      <Button variant="primary">
        <i className="bi bi-eye-fill"></i>
      </Button>
    )
  }
];

const columns = [
  {
    name: "ID",
    selector: row => row.code,
    sortable: true
  },
  {
    name: "Nr. Fornecedores",
    selector: row => row.total_suppliers,
    sortable: true
  },
  {
    name: "Visualizadas",
    selector: row => row.viewed,
    sortable: false
  },
  {
    name: "Respondidas",
    selector: row => row.answered,
    sortable: true
  },
  {
    name: "Data Envio",
    selector: row => row.sent_at,
    sortable: false
  },
  {
    name: "Data Término",
    selector: row => row.finish_at,
    sortable: false
  },
  {
    name: "Status",
    selector: row => row.status,
    sortable: false
  },
  {
    name: "",
    selector: row => row.action,
    sortable: false
  }
];

export default class QuotationIndex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: localStorage.getItem("token") != null,
      isLoggedInMessage: "",
      loadingData: true,
      /* data: [], */
      data: rows,
      histories: [],
      locations: [],
      computers: []
    };

    this.handleProp = this.handleProp.bind(this);
    this.handleMergeProp = this.handleMergeProp.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.expandedComponent = this.expandedComponent.bind(this);
    this.getTicketDetail = this.getTicketDetail.bind(this);
  }

  componentDidMount() {
    /* if (this.state.isLoggedIn) {
     *   this.fetchData();
     * } else {
     *   console.log("ComponentDidMount not logged in!!");
     * }
     */
  }

  fetchData() {
    /* const user = JSON.parse(localStorage.getItem("user"));

	 * API.get(`/sigat-api/otrs/tickets/index?customer=${user.username}`)
	 *   .then(response => {
	 *     console.log(response.data);
	 *     console.log(response.data.TicketID == undefined);

	 *     let tickets = [];
	 *     let histories = [];
	 *     let locations = [];
	 *     let computers = [];

	 *     if (response.data.TicketID == undefined) {
	 *       this.setState({
	 *         loadingData: false,
	 *         data: [
	 *           {
	 *             id: 0,
	 *             action: <span className="bi bi-bug"></span>,
	 *             TicketNumber: <span className="bi bi-bug"></span>,
	 *             Created: <span className="bi bi-bug"></span>,
	 *             Owner: <span className="bi bi-bug"></span>,
	 *             State: <span className="bi bi-bug"></span>
	 *           }
	 *         ]
	 *       });
	 *     } else {
	 *       response.data.TicketID.forEach(tid => {
	 *         tickets.push({
	 *           id: tid,
	 *           action: (
	 *             <Button
	 *               variant="outline-primary"
	 *               onClick={() => this.getTicketDetail(tid)}
	 *             >
	 *               <span className="bi bi-bezier"></span>
	 *             </Button>
	 *           ),
	 *           TicketNumber: `Ticket ID ${tid}`,
	 *           Created: (
	 *             <Spinner
	 *               animation="border"
	 *               role="status"
	 *               variant="info"
	 *               size="sm"
	 *             >
	 *               <span className="visually-hidden">Loading...</span>
	 *             </Spinner>
	 *           ),
	 *           Owner: (
	 *             <Spinner
	 *               animation="border"
	 *               role="status"
	 *               variant="info"
	 *               size="sm"
	 *             >
	 *               <span className="visually-hidden">Loading...</span>
	 *             </Spinner>
	 *           ),
	 *           State: (
	 *             <Spinner
	 *               animation="border"
	 *               role="status"
	 *               variant="info"
	 *               size="sm"
	 *             >
	 *               <span className="visually-hidden">Loading...</span>
	 *             </Spinner>
	 *           )
	 *         });

	 *         histories.push({
	 *           TicketID: tid,
	 *           histories: []
	 *         });

	 *         locations.push({
	 *           TicketID: tid,
	 *           locations: []
	 *         });

	 *         computers.push({
	 *           TicketID: tid,
	 *           computers: []
	 *         });
	 *       });

	 *       this.setState(
	 *         {
	 *           loadingData: false,
	 *           data: tickets,
	 *           histories: histories,
	 *           locations: locations,
	 *           computers: computers
	 *         },
	 *         () => {
	 *           response.data.TicketID.forEach(tid => {
	 *             this.getTicketDetail(tid);
	 *           });
	 *         }
	 *       );
	 *     }
	 *   })
	 *   .catch(error => {
	 *     console.log("Error with getting customer tickets");
	 *     console.error(error);
	 *   }); */
  }

  expandedComponent({ data }) {
    return (
      <>
        <Tabs defaultActiveKey="info" id="painel" className="mb-3">
          <Tab eventKey="info" title="Informações">
            Detalhamento da cotação
          </Tab>
          <Tab eventKey="location" title="Produtos">
            Produtos associados
          </Tab>
          <Tab eventKey="equipment" title="Oferta">
            Ofertas enviadas
          </Tab>
          <Tab eventKey="description" title="Impostos e Taxas">
            Impostos e demais taxas
          </Tab>
        </Tabs>
      </>
    );
  }

  // FIXME when loading, fields with "loading" placeholder isn't updating - occurs only when tab is open before dynamic loading
  getTicketDetail(ticketId) {
    /* API.get(
	 *   `/sigat-api/otrs/tickets/show?ticket_id=${ticketId}&all_articles=true`
	 * )
	 *   .then(response => {
	 *     let ticket = null;

	 *     if (Object.keys(response.data.sigatapi_errors).length !== 0) {
	 *       console.log("Error getting data from backend!");
	 *       ticket = {
	 *         id: ticketId,
	 *         action: (
	 *           <Button
	 *             variant="outline-primary"
	 *             onClick={() => this.getTicketDetail(ticketId)}
	 *           >
	 *             <span className="bi bi-bezier"></span>
	 *           </Button>
	 *         ),
	 *         TicketNumber: <span className="bi bi-bug"></span>,
	 *         Created: <span className="bi bi-bug"></span>,
	 *         Owner: <span className="bi bi-bug"></span>,
	 *         State: <span className="bi bi-bug"></span>
	 *       };
	 *     } else {
	 *       ticket = {
	 *         id: ticketId,
	 *         action: (
	 *           <Button
	 *             variant="outline-info"
	 *             onClick={() => this.getTicketDetail(ticketId)}
	 *           >
	 *             <span className="bi bi-bezier"></span>
	 *           </Button>
	 *         )
	 *       };
	 *       ticket = { ...response.data.Ticket[0], ...ticket };
	 *     }

	 *     this.setState((state, props) => {
	 *       return {
	 *         data: state.data.map(item => (item.id == ticketId ? ticket : item))
	 *       };
	 *     });
	 *   })
	 *   .catch(error => {
	 *     console.log(`Error getting single Ticket: ID ${ticketId} `);
	 *   }); */
  }

  handleProp(prop, value) {
    this.setState({ [prop]: value }, () => {
      /* TODO implement function signature with a callback. */
      if (prop === "isLoggedIn" && value === true) this.fetchData();
    });
  }

  handleMergeProp(prop, value, tid) {
    this.setState((state, props) => {
      return {
        [prop]: state[prop].map(item =>
          item.TicketID === tid ? { ...item, [prop]: value } : item
        )
      };
    });
  }

  render() {
    const panel = (
      <DataTable columns={columns} data={this.state.data} pagination />
    );

    return (
      <>
        <Row xs={1} md={3} className="g-4 container">
          <Col>
            <StatsCard
              icon="bi bi-file-spreadsheet-fill"
              title="1 478 286"
              text="Cotações Solicitadas"
            />
          </Col>
          <Col>
            <StatsCard
              icon="bi bi-bar-chart-line-fill"
              title="987 593"
              text="Cotações Respondidas"
            />
          </Col>
          <Col>
            <StatsCard
              icon="bi bi-envelope-fill"
              title="287 638"
              text="Itens Comprados"
            />
          </Col>
        </Row>
        <h1>Cotações em Aberto</h1>
        <div className="App">
          <div className="container">{panel}</div>
          <br />
        </div>
      </>
    );
  }
}
