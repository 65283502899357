import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Badge from "react-bootstrap/Badge";

import DataTable from "react-data-table-component";

import logo_client from "../images/logo.svg";

/* import item_data from "../data/items.json"; */

const rows = [
  {
    product: {
      name: "Caneta Verde",
      description: "Caneta verde ponta fina recarregável"
    },
    quantity: (
      <Badge pill bg="info">
        10
      </Badge>
    ),
    unit: "Pç",
    action: (
      <Button
        variant="primary"
        onClick={() => console.log("TODO implement a function call here!!")}
      >
        Efetuar Lances
      </Button>
    )
  },
  {
    product: {
      name: "Borracha",
      description: "Borracha branca para lápis e caneta"
    },
    quantity: (
      <Badge pill bg="info">
        5
      </Badge>
    ),
    unit: "Pç",
    action: (
      <Button
        variant="primary"
        onClick={() => console.log("TODO implement a function call here!!")}
      >
        Efetuar Lances
      </Button>
    )
  },
  {
    product: {
      name: "Lápis",
      description: "Lápis nº2 para desenho técnico"
    },
    quantity: (
      <Badge pill bg="info">
        20
      </Badge>
    ),
    unit: "Pç",
    action: (
      <Button
        variant="primary"
        onClick={() => console.log("TODO implement a function call here!!")}
      >
        Efetuar Lances
      </Button>
    )
  },
  {
    product: {
      name: "Apontador",
      description: "Apontador com case incluso"
    },
    quantity: (
      <Badge pill bg="info">
        2
      </Badge>
    ),
    unit: "Pç",
    action: (
      <Button
        variant="primary"
        onClick={() => console.log("TODO implement a function call here!!")}
      >
        Efetuar Lances
      </Button>
    )
  }
];

const columns = [
  {
    name: "Produto",
    selector: row => row.product.name,
    sortable: true
  },
  {
    name: "Quantidade",
    selector: row => row.quantity,
    sortable: true
  },
  {
    name: "Unidade",
    selector: row => row.unit,
    sortable: true
  },
  {
    name: "",
    selector: row => row.action,
    sortable: false
  }
];

export default class BidForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: localStorage.getItem("token") != null,
      isLoggedInMessage: "",
      loadingData: true,
      /* data: [], */
      data: rows,
      histories: [],
      locations: [],
      computers: []
    };

    this.handleProp = this.handleProp.bind(this);
    this.handleMergeProp = this.handleMergeProp.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.expandedComponent = this.expandedComponent.bind(this);
    this.getTicketDetail = this.getTicketDetail.bind(this);
  }

  componentDidMount() {
    /* if (this.state.isLoggedIn) {
     *   this.fetchData();
     * } else {
     *   console.log("ComponentDidMount not logged in!!");
     * }
     */
  }

  fetchData() {
    /* const user = JSON.parse(localStorage.getItem("user"));

	 * API.get(`/sigat-api/otrs/tickets/index?customer=${user.username}`)
	 *   .then(response => {
	 *     console.log(response.data);
	 *     console.log(response.data.TicketID == undefined);

	 *     let tickets = [];
	 *     let histories = [];
	 *     let locations = [];
	 *     let computers = [];

	 *     if (response.data.TicketID == undefined) {
	 *       this.setState({
	 *         loadingData: false,
	 *         data: [
	 *           {
	 *             id: 0,
	 *             action: <span className="bi bi-bug"></span>,
	 *             TicketNumber: <span className="bi bi-bug"></span>,
	 *             Created: <span className="bi bi-bug"></span>,
	 *             Owner: <span className="bi bi-bug"></span>,
	 *             State: <span className="bi bi-bug"></span>
	 *           }
	 *         ]
	 *       });
	 *     } else {
	 *       response.data.TicketID.forEach(tid => {
	 *         tickets.push({
	 *           id: tid,
	 *           action: (
	 *             <Button
	 *               variant="outline-primary"
	 *               onClick={() => this.getTicketDetail(tid)}
	 *             >
	 *               <span className="bi bi-bezier"></span>
	 *             </Button>
	 *           ),
	 *           TicketNumber: `Ticket ID ${tid}`,
	 *           Created: (
	 *             <Spinner
	 *               animation="border"
	 *               role="status"
	 *               variant="info"
	 *               size="sm"
	 *             >
	 *               <span className="visually-hidden">Loading...</span>
	 *             </Spinner>
	 *           ),
	 *           Owner: (
	 *             <Spinner
	 *               animation="border"
	 *               role="status"
	 *               variant="info"
	 *               size="sm"
	 *             >
	 *               <span className="visually-hidden">Loading...</span>
	 *             </Spinner>
	 *           ),
	 *           State: (
	 *             <Spinner
	 *               animation="border"
	 *               role="status"
	 *               variant="info"
	 *               size="sm"
	 *             >
	 *               <span className="visually-hidden">Loading...</span>
	 *             </Spinner>
	 *           )
	 *         });

	 *         histories.push({
	 *           TicketID: tid,
	 *           histories: []
	 *         });

	 *         locations.push({
	 *           TicketID: tid,
	 *           locations: []
	 *         });

	 *         computers.push({
	 *           TicketID: tid,
	 *           computers: []
	 *         });
	 *       });

	 *       this.setState(
	 *         {
	 *           loadingData: false,
	 *           data: tickets,
	 *           histories: histories,
	 *           locations: locations,
	 *           computers: computers
	 *         },
	 *         () => {
	 *           response.data.TicketID.forEach(tid => {
	 *             this.getTicketDetail(tid);
	 *           });
	 *         }
	 *       );
	 *     }
	 *   })
	 *   .catch(error => {
	 *     console.log("Error with getting customer tickets");
	 *     console.error(error);
	 *   }); */
  }

  expandedComponent({ data }) {
    return (
      <>
        <br />
        <Container>
          <Tabs defaultActiveKey="bid" id="painel" className="mb-3">
            <Tab eventKey="bid" title="Realizar Oferta">
              <Row>
                <Col sm="7">
                  <Row>
                    <Col sm="4">
                      <Form.Group controlId="formPlaintextEmail">
                        <Form.Label column>Valor</Form.Label>
                        <Form.Control />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group controlId="formPlaintextEmail">
                        <Form.Label column>IPI</Form.Label>
                        <Form.Control />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group controlId="formPlaintextEmail">
                        <Form.Label column>ICMS</Form.Label>
                        <Form.Control />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="8">
                      <Form.Group controlId="formPlaintextEmail">
                        <Form.Label column>Prazo Pagamento</Form.Label>
                        <Form.Control />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group controlId="formPlaintextEmail">
                        <Form.Label column>NBM</Form.Label>
                        <Form.Control />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="8">
                      <Form.Group controlId="formPlaintextEmail">
                        <Form.Label column>Prazo Entrega</Form.Label>
                        <Form.Control />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group controlId="formPlaintextEmail">
                        <Form.Label column>Valor Total</Form.Label>
                        <InputGroup>
                          <InputGroup.Text id="total">R$</InputGroup.Text>
                          <Form.Control
                            disabled
                            aria-label="Valor Total"
                            aria-describedby="total"
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col sm="5">
                  <Form.Group controlId="txtObs">
                    <Form.Label>Observação</Form.Label>
                    <Form.Control as="textarea" rows={8} />
                  </Form.Group>
                </Col>
              </Row>
              <br />
            </Tab>
            <Tab eventKey="info" title="Mais Informações">
              <h4>Produto Sendo Cotado</h4>
              <Row>
                <Form.Group className="mb-3" controlId="formPlaintextEmail">
                  <Form.Label column sm="2">
                    Nome
                  </Form.Label>
                  <Form.Control readOnly defaultValue={data.product.name} />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="mb-3" controlId="formDescription">
                  <Form.Label>Descrição</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={10}
                    readOnly
                    value={data.product.description}
                  />
                </Form.Group>
              </Row>
            </Tab>
          </Tabs>
        </Container>
      </>
    );
  }

  // FIXME when loading, fields with "loading" placeholder isn't updating - occurs only when tab is open before dynamic loading
  getTicketDetail(ticketId) {
    /* API.get(
	 *   `/sigat-api/otrs/tickets/show?ticket_id=${ticketId}&all_articles=true`
	 * )
	 *   .then(response => {
	 *     let ticket = null;

	 *     if (Object.keys(response.data.sigatapi_errors).length !== 0) {
	 *       console.log("Error getting data from backend!");
	 *       ticket = {
	 *         id: ticketId,
	 *         action: (
	 *           <Button
	 *             variant="outline-primary"
	 *             onClick={() => this.getTicketDetail(ticketId)}
	 *           >
	 *             <span className="bi bi-bezier"></span>
	 *           </Button>
	 *         ),
	 *         TicketNumber: <span className="bi bi-bug"></span>,
	 *         Created: <span className="bi bi-bug"></span>,
	 *         Owner: <span className="bi bi-bug"></span>,
	 *         State: <span className="bi bi-bug"></span>
	 *       };
	 *     } else {
	 *       ticket = {
	 *         id: ticketId,
	 *         action: (
	 *           <Button
	 *             variant="outline-info"
	 *             onClick={() => this.getTicketDetail(ticketId)}
	 *           >
	 *             <span className="bi bi-bezier"></span>
	 *           </Button>
	 *         )
	 *       };
	 *       ticket = { ...response.data.Ticket[0], ...ticket };
	 *     }

	 *     this.setState((state, props) => {
	 *       return {
	 *         data: state.data.map(item => (item.id == ticketId ? ticket : item))
	 *       };
	 *     });
	 *   })
	 *   .catch(error => {
	 *     console.log(`Error getting single Ticket: ID ${ticketId} `);
	 *   }); */
  }

  handleProp(prop, value) {
    this.setState({ [prop]: value }, () => {
      /* TODO implement function signature with a callback. */
      if (prop === "isLoggedIn" && value === true) this.fetchData();
    });
  }

  handleMergeProp(prop, value, tid) {
    this.setState((state, props) => {
      return {
        [prop]: state[prop].map(item =>
          item.TicketID === tid ? { ...item, [prop]: value } : item
        )
      };
    });
  }

  render() {
    const panel = (
      <DataTable
        columns={columns}
        data={this.state.data}
        expandableRowsComponent={this.expandedComponent}
        expandableRows
        pagination
      />
    );

    return (
      <>
        <Row xs={1} md={4} className="g-4">
          <Col>
            <img
              src={logo_client}
              alt="Logo Cliente"
              width="100"
              height="100"
            />
          </Col>
          <Col>
            <Card border="info">
              <Card.Header>Cotação</Card.Header>
              <Card.Body border="secondary">
                <Card.Title>45678</Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <small>Prazo para envio da proposta</small>
            <br />
            <em>20/09/2021</em>
          </Col>
          <Col>
            <small>Fornecedor</small>
            <br />
            <em>KALUNGA</em>
            <br />
            <br />
            <em>Mário Sérgio</em>
            <br />
            <small>mario.sergio@kalunga.com.br</small>
          </Col>
        </Row>
        <h1>Itens Disponíveis</h1>
        <div className="App">
          <div className="container">{panel}</div>
          <br />
        </div>
      </>
    );
  }
}
