import React from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
/* import InputGroup from "react-bootstrap/InputGroup";
 * import Tab from "react-bootstrap/Tab";
 * import Tabs from "react-bootstrap/Tabs";
 * import Col from "react-bootstrap/Col"; */
import Row from "react-bootstrap/Row";
import DataTable from "react-data-table-component";
import registers from "./data/products.json";

/* TODO implement as a helper to re-use in another CRUD */
/* import Helper from "./Helper"; */
/* const rows = Helper.loadRegisters(registers); */
/* Helper.logout(); */

let rows = [];
registers.forEach(register => {
  rows.push({
    ...register,
    action: (
      <Button variant="danger">
        <i className="bi bi-trash-fill"></i>
      </Button>
    )
  });
});

const columns = [
  {
    name: "ID",
    selector: row => row.id,
    sortable: true
  },
  {
    name: "Nome",
    selector: row => row.name,
    sortable: true
  },
  {
    name: "Unidade",
    selector: row => row.unit,
    sortable: true
  },
  {
    name: "Remover",
    selector: row => row.action,
    sortable: false
  }
];

export default class ProductCRUD extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      registers: registers,
      data: rows
    };

    this.expandedComponent = this.expandedComponent.bind(this);
  }

  expandedComponent({ data }) {
    return (
      <>
        <br />
        <Container>
          <h4>
            Produto #{data.id}&nbsp;
            <Button
              variant="warning"
              onClick={() => console.log("TODO implement EDIT here!")}
            >
              <i className="bi bi-pencil-square"></i>
            </Button>
          </h4>
          <Row>
            <Form.Group className="mb-3" controlId="txtName">
              <Form.Label column sm="2">
                Nome
              </Form.Label>
              <Form.Control readOnly defaultValue={data.name} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3" controlId="txtUnit">
              <Form.Label column sm="2">
                Unidade
              </Form.Label>
              <Form.Control readOnly defaultValue={data.unit} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3" controlId="txtDescription">
              <Form.Label>Descrição</Form.Label>
              <Form.Control
                as="textarea"
                rows={10}
                readOnly
                value={data.description}
              />
            </Form.Group>
          </Row>
          {/* <Tabs defaultActiveKey="info" id="painel" className="mb-3">
              <Tab eventKey="info" title="Mais Informações">
              </Tab>
			  </Tabs> */}
        </Container>
      </>
    );
  }

  render() {
    const panel = (
      <DataTable
        columns={columns}
        data={this.state.data}
        expandableRowsComponent={this.expandedComponent}
        expandableRows
        pagination
      />
    );

    return (
      <>
        <h1>
          Cadastro de Produtos&nbsp;
          <Button
            variant="success"
            onClick={() => console.log("TODO implement CREATE here!")}
          >
            <i className="bi bi-file-earmark-plus-fill"></i>
          </Button>
        </h1>
        <div className="App">
          <div className="container">{panel}</div>
          <br />
        </div>
      </>
    );
  }
}
