import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Card from "react-bootstrap/Card";
import DataTable from "react-data-table-component";

const rows = [
  {
    product: "Caneta Verde",
    quantity: 10,
    action: (
      <Button
        variant="primary"
        onClick={() => console.log("TODO implement a function call here!!")}
      >
        Visualizar Lances
      </Button>
    )
  },
  {
    product: "Borracha",
    quantity: 5,
    action: (
      <Button
        variant="primary"
        onClick={() => console.log("TODO implement a function call here!!")}
      >
        Visualizar Lances
      </Button>
    )
  },
  {
    product: "Lápis nº2",
    quantity: 20,
    action: (
      <Button
        variant="primary"
        onClick={() => console.log("TODO implement a function call here!!")}
      >
        Visualizar Lances
      </Button>
    )
  },
  {
    product: "Apontador",
    quantity: 5,
    action: (
      <Button
        variant="primary"
        onClick={() => console.log("TODO implement a function call here!!")}
      >
        Visualizar Lances
      </Button>
    )
  }
];

const columns = [
  {
    name: "Produto",
    selector: row => row.product,
    sortable: true
  },
  {
    name: "Quantidade",
    selector: row => row.quantity,
    sortable: true
  },
  {
    name: "",
    selector: row => row.action,
    sortable: false
  }
];

export default class QuotationShow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: localStorage.getItem("token") != null,
      isLoggedInMessage: "",
      loadingData: true,
      /* data: [], */
      data: rows,
      histories: [],
      locations: [],
      computers: []
    };

    this.handleProp = this.handleProp.bind(this);
    this.handleMergeProp = this.handleMergeProp.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.expandedComponent = this.expandedComponent.bind(this);
    this.getTicketDetail = this.getTicketDetail.bind(this);
  }

  componentDidMount() {
    /* if (this.state.isLoggedIn) {
     *   this.fetchData();
     * } else {
     *   console.log("ComponentDidMount not logged in!!");
     * }
     */
  }

  fetchData() {
    /* const user = JSON.parse(localStorage.getItem("user"));

	 * API.get(`/sigat-api/otrs/tickets/index?customer=${user.username}`)
	 *   .then(response => {
	 *     console.log(response.data);
	 *     console.log(response.data.TicketID == undefined);

	 *     let tickets = [];
	 *     let histories = [];
	 *     let locations = [];
	 *     let computers = [];

	 *     if (response.data.TicketID == undefined) {
	 *       this.setState({
	 *         loadingData: false,
	 *         data: [
	 *           {
	 *             id: 0,
	 *             action: <span className="bi bi-bug"></span>,
	 *             TicketNumber: <span className="bi bi-bug"></span>,
	 *             Created: <span className="bi bi-bug"></span>,
	 *             Owner: <span className="bi bi-bug"></span>,
	 *             State: <span className="bi bi-bug"></span>
	 *           }
	 *         ]
	 *       });
	 *     } else {
	 *       response.data.TicketID.forEach(tid => {
	 *         tickets.push({
	 *           id: tid,
	 *           action: (
	 *             <Button
	 *               variant="outline-primary"
	 *               onClick={() => this.getTicketDetail(tid)}
	 *             >
	 *               <span className="bi bi-bezier"></span>
	 *             </Button>
	 *           ),
	 *           TicketNumber: `Ticket ID ${tid}`,
	 *           Created: (
	 *             <Spinner
	 *               animation="border"
	 *               role="status"
	 *               variant="info"
	 *               size="sm"
	 *             >
	 *               <span className="visually-hidden">Loading...</span>
	 *             </Spinner>
	 *           ),
	 *           Owner: (
	 *             <Spinner
	 *               animation="border"
	 *               role="status"
	 *               variant="info"
	 *               size="sm"
	 *             >
	 *               <span className="visually-hidden">Loading...</span>
	 *             </Spinner>
	 *           ),
	 *           State: (
	 *             <Spinner
	 *               animation="border"
	 *               role="status"
	 *               variant="info"
	 *               size="sm"
	 *             >
	 *               <span className="visually-hidden">Loading...</span>
	 *             </Spinner>
	 *           )
	 *         });

	 *         histories.push({
	 *           TicketID: tid,
	 *           histories: []
	 *         });

	 *         locations.push({
	 *           TicketID: tid,
	 *           locations: []
	 *         });

	 *         computers.push({
	 *           TicketID: tid,
	 *           computers: []
	 *         });
	 *       });

	 *       this.setState(
	 *         {
	 *           loadingData: false,
	 *           data: tickets,
	 *           histories: histories,
	 *           locations: locations,
	 *           computers: computers
	 *         },
	 *         () => {
	 *           response.data.TicketID.forEach(tid => {
	 *             this.getTicketDetail(tid);
	 *           });
	 *         }
	 *       );
	 *     }
	 *   })
	 *   .catch(error => {
	 *     console.log("Error with getting customer tickets");
	 *     console.error(error);
	 *   }); */
  }

  expandedComponent({ data }) {
    return (
      <>
        <Tabs defaultActiveKey="info" id="painel" className="mb-3">
          <Tab eventKey="info" title="Informações">
            Detalhamento da cotação
          </Tab>
          <Tab eventKey="location" title="Produtos">
            Produtos associados
          </Tab>
          <Tab eventKey="equipment" title="Oferta">
            Ofertas enviadas
          </Tab>
          <Tab eventKey="description" title="Impostos e Taxas">
            Impostos e demais taxas
          </Tab>
        </Tabs>
      </>
    );
  }

  // FIXME when loading, fields with "loading" placeholder isn't updating - occurs only when tab is open before dynamic loading
  getTicketDetail(ticketId) {
    /* API.get(
	 *   `/sigat-api/otrs/tickets/show?ticket_id=${ticketId}&all_articles=true`
	 * )
	 *   .then(response => {
	 *     let ticket = null;

	 *     if (Object.keys(response.data.sigatapi_errors).length !== 0) {
	 *       console.log("Error getting data from backend!");
	 *       ticket = {
	 *         id: ticketId,
	 *         action: (
	 *           <Button
	 *             variant="outline-primary"
	 *             onClick={() => this.getTicketDetail(ticketId)}
	 *           >
	 *             <span className="bi bi-bezier"></span>
	 *           </Button>
	 *         ),
	 *         TicketNumber: <span className="bi bi-bug"></span>,
	 *         Created: <span className="bi bi-bug"></span>,
	 *         Owner: <span className="bi bi-bug"></span>,
	 *         State: <span className="bi bi-bug"></span>
	 *       };
	 *     } else {
	 *       ticket = {
	 *         id: ticketId,
	 *         action: (
	 *           <Button
	 *             variant="outline-info"
	 *             onClick={() => this.getTicketDetail(ticketId)}
	 *           >
	 *             <span className="bi bi-bezier"></span>
	 *           </Button>
	 *         )
	 *       };
	 *       ticket = { ...response.data.Ticket[0], ...ticket };
	 *     }

	 *     this.setState((state, props) => {
	 *       return {
	 *         data: state.data.map(item => (item.id == ticketId ? ticket : item))
	 *       };
	 *     });
	 *   })
	 *   .catch(error => {
	 *     console.log(`Error getting single Ticket: ID ${ticketId} `);
	 *   }); */
  }

  handleProp(prop, value) {
    this.setState({ [prop]: value }, () => {
      /* TODO implement function signature with a callback. */
      if (prop === "isLoggedIn" && value === true) this.fetchData();
    });
  }

  handleMergeProp(prop, value, tid) {
    this.setState((state, props) => {
      return {
        [prop]: state[prop].map(item =>
          item.TicketID === tid ? { ...item, [prop]: value } : item
        )
      };
    });
  }

  render() {
    const panel = (
      <DataTable
        columns={columns}
        data={this.state.data}
        expandableRowsComponent={this.expandedComponent}
        expandableRows
        pagination
      />
    );

    return (
      <>
        <Row xs={1} md={4} className="g-4 container">
          <Col>
            <Card border="info">
              <Card.Header>Cotação ID</Card.Header>
              <Card.Body border="primary">
                <Card.Title>456789</Card.Title>
                <Card.Subtitle className="text-muted">
                  <small>Finaliza em 21/09/2021</small>
                </Card.Subtitle>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card border="info">
              <Card.Header># Fornecedores</Card.Header>
              <Card.Body border="secondary">
                <Card.Title>4</Card.Title>
                <Card.Subtitle className="text-muted">
                  <small>Participando</small>
                </Card.Subtitle>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card border="info">
              <Card.Header># Visualizadas</Card.Header>
              <Card.Body border="secondary">
                <Card.Title>3</Card.Title>
                <Card.Subtitle className="text-muted">
                  <small>Cotações</small>
                </Card.Subtitle>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card border="info">
              <Card.Header># Respondidas</Card.Header>
              <Card.Body border="secondary">
                <Card.Title>2</Card.Title>
                <Card.Subtitle className="text-muted">
                  <small>Cotações</small>
                </Card.Subtitle>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <br />
        <h1>Itens da Cotação</h1>
        <div className="App">
          <div className="container">{panel}</div>
        </div>
        <br />
      </>
    );
  }
}
