import React, { Component } from "react";
import "./Menu.css";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import UserBadge from "./auth/UserBadge";
/* import logo from "./images/botslab_icon_branco.png"; */
import logo from "./images/botslab_logo.svg";

export default class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: localStorage.getItem("token") != null,
      isLoggedInMessage: "",
      loadingData: true,
      data: [],
      histories: [],
      locations: [],
      computers: []
    };

    /* this.handleProp = this.handleProp.bind(this);
     * this.handleMergeProp = this.handleMergeProp.bind(this);
     * this.fetchData = this.fetchData.bind(this);
     * this.expandedComponent = this.expandedComponent.bind(this);
     * this.getTicketDetail = this.getTicketDetail.bind(this);
     */
  }

  render() {
    return (
      <div>
        <Navbar bg="primary" expand="sm" sticky="top">
          <Container>
            <Navbar.Brand href="#home" className="text-white">
              <img
                alt=""
                src={logo}
                width="100"
                height="25"
                className="d-inline-block align-top"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="/" className="text-white">
                  Home
                </Nav.Link>
                <NavDropdown
                  title={<span className="text-white my-auto">Cotações</span>}
                  id="navbarScrollingDropdown"
                  className="text-white"
                >
                  <NavDropdown.Item href="/cotacoes/">
                    Lista de Cotações
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/cotacoes/show">
                    Detalhes da Cotação
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/lances/formulario">
                    Formulário da Cotação
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                  title={<span className="text-white my-auto">Cadastros</span>}
                  id="navbarScrollingDropdown"
                  className="text-white"
                >
                  <NavDropdown.Item href="/produtos">Produtos</NavDropdown.Item>
                  <NavDropdown.Item href="/lances/formulario">
                    Lances
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/">[TODO] Cotações</NavDropdown.Item>
                  <NavDropdown.Item href="/">[TODO] Itens</NavDropdown.Item>
                  <NavDropdown.Item href="/">
                    [TODO] Fornecedores
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/">
                    [TODO] Impostos/Taxas
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
              <Nav>
                <UserBadge
                  isLoggedIn={this.state.isLoggedIn}
                  isLoggedInMessage={this.state.isLoggedInMessage}
                  onIsLoggedInChange={this.handleProp}
                />
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }
}
