import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import QuotationIndex from "./quotations/QuotationIndex";
import QuotationShow from "./quotations/QuotationShow";
import BidForm from "./bids/BidForm";
import ProductCRUD from "./ProductCRUD";
import QuotationCRUD from "./QuotationCRUD";

/* TODO implement it!
 * import reportWebVitals from './reportWebVitals'; */
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

const rootPath = <App component={<QuotationIndex />} />;
const quotationShow = <App component={<QuotationShow />} />;
const bidForm = <App component={<BidForm />} />;
const productCRUD = <App component={<ProductCRUD />} />;
const quotationCRUD = <App component={<QuotationCRUD />} />;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={rootPath} />
      <Route path="/cotacoes" element={quotationCRUD} />
      <Route path="/cotacoes/show" element={quotationShow} />
      <Route path="/lances/formulario" element={bidForm} />
      <Route path="/produtos" element={productCRUD} />
      <Route path="*" element={rootPath} />
    </Routes>
  </BrowserRouter>
);
