import React, { Component } from "react";
import "./App.css";
import Menu from "./Menu";
import Container from "react-bootstrap/Container";

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: localStorage.getItem("token") != null,
      isLoggedInMessage: "",
      loadingData: true,
      component: this.props.component
    };
  }

  render() {
    return (
      <>
        <Container>
          <Menu />
          <div className="App">
            <br />
            {this.state.component}
          </div>
        </Container>
      </>
    );
  }
}
