import React from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
/* import Tab from "react-bootstrap/Tab";
 * import Tabs from "react-bootstrap/Tabs";
 * import Col from "react-bootstrap/Col"; */
import Row from "react-bootstrap/Row";
import DataTable from "react-data-table-component";
import registers from "./data/quotations.json";
import API from "./auth/Api";

/* TODO implement as a helper to re-use in another CRUD */
/* import Helper from "./Helper"; */
/* const rows = Helper.loadRegisters(registers); */
/* Helper.logout(); */

let rows = [];
registers.forEach(register => {
  rows.push({
    ...register,
    action: (
      <Button variant="danger">
        <i className="bi bi-trash-fill"></i>
      </Button>
    )
  });
});

const columns = [
  {
    name: "ID",
    selector: row => row.id,
    sortable: true
  },
  {
    name: "Data Limite",
    selector: row => row.due,
    sortable: true
  },
  {
    name: "Data Criação",
    selector: row => row.create_at,
    sortable: true
  },
  {
    name: "Última Atualização",
    selector: row => row.updated_at,
    sortable: true
  },
  {
    name: "Remover",
    selector: row => row.action,
    sortable: false
  }
];

export default class QuotationCRUD extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      registers: registers,
      data: rows,
      api: "/api/quotations/"
    };

    this.expandedComponent = this.expandedComponent.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    event.preventDefault();
    this.setState({
      api: event.target.value
    });
  }

  componentDidMount() {
    /* if (this.state.isLoggedIn) {
     *   this.fetchData();
     * } else {
     *   console.log("ComponentDidMount not logged in!!");
     * } */
  }

  fetchData() {
    /* const user = JSON.parse(localStorage.getItem("user")); */

    console.log("Init fetch Data: " + this.state.api);

    API.get(this.state.api)
      .then(response => {
        console.log(response);
        console.log(response.data);
        this.setState({
          data: response.data
        });
      })
      .catch(error => {
        console.log("Error with getting customer tickets");
        console.error(error);
      });
  }

  expandedComponent({ data }) {
    return (
      <>
        <br />
        <Container>
          <h4>
            Cotação #{data.id}&nbsp;
            <Button
              variant="warning"
              onClick={() => console.log("TODO implement EDIT here!")}
            >
              <i className="bi bi-pencil-square"></i>
            </Button>
          </h4>
          <Row>
            <Form.Group className="mb-3" controlId="txtName">
              <Form.Label column sm="2">
                Data Limite
              </Form.Label>
              <Form.Control readOnly defaultValue={data.due} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3" controlId="txtUnit">
              <Form.Label column sm="2">
                Data Criação
              </Form.Label>
              <Form.Control readOnly defaultValue={data.created_at} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3" controlId="txtUnit">
              <Form.Label column sm="2">
                Data Atualização
              </Form.Label>
              <Form.Control readOnly defaultValue={data.updated_at} />
            </Form.Group>
          </Row>
        </Container>
      </>
    );
  }

  render() {
    const panel = (
      <DataTable
        columns={columns}
        data={this.state.data}
        expandableRowsComponent={this.expandedComponent}
        expandableRows
        pagination
      />
    );

    return (
      <>
        <h1>
          Cadastro de Cotações&nbsp;
          <Button
            variant="success"
            onClick={() => console.log("TODO implement CREATE here!")}
          >
            <i className="bi bi-file-earmark-plus-fill"></i>
          </Button>
        </h1>
        <Form.Group className="mb-3" controlId="formApi">
          <Form.Label>API End Point</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type="text"
              placeholder="/api/quotations/"
              onChange={this.handleChange}
              value={this.state.api}
            />
            <Button
              id="btnApi"
              variant="warning"
              onClick={() => this.fetchData()}
              className="bi bi-node-plus"
            ></Button>
          </InputGroup>
        </Form.Group>
        <div className="App">
          <div className="container">{panel}</div>
          <br />
        </div>
      </>
    );
  }
}
